export const numbersAndMinus = $event => {
  // only Numbers
  const keyCode = $event.keyCode ? $event.keyCode : $event.which
  if (keyCode === 46 || keyCode === 45) {
    return // allow dot
  }
  if ((keyCode < 47 || keyCode > 58)) {
    $event.preventDefault()
  }
}
export const onlyNumbers = $event => {
  // only Numbers
  const keyCode = $event.keyCode ? $event.keyCode : $event.which
  if (keyCode < 48 || keyCode > 58) {
    $event.preventDefault()
  }
}

export const onlyNumberDot = $event => {
  // only Numbers with Dot
  const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
  if (keyCode === 44) {
    $event.preventDefault() // comma out
  }
  if (keyCode === 46) {
    return // allow dot
  }
  if ((keyCode < 48 || keyCode > 58)) {
    $event.preventDefault()
  }
}

// generate a random number in a range min and max numbers
export const between = (min, max) => Math.floor(
  Math.random() * (max - min) + min,
)

export const formatPrice = value => {
  const val = (value / 1).toFixed(2).replace('.', ',')

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
export const calcTwoDecimals = val => val.toString().match(/^\d+(?:\.\d{0,2})?/)[0]
