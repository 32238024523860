<template>
  <v-card
    id="sport-form"
    min-height="350"
  >
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <tab-general
          :data-params="generalData"
          :tab="tab"
          :option="option"
          @changeTab="changeTab"
          @save="saveAction"
        ></tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import {
  createProduct,
  editProduct,
  getProduct,
} from '@api'

// demos
import TabGeneral from './TabGeneral.vue'

export default {
  components: {
    TabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()

    const tab = ref(0)
    const option = ref(1)
    const dataId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const generalData = ref({
      name: null,
      measurement_unit: null,
      description: null,
      stock: null,
      stock_alert: null,
      status: 'A',
    })
    const postData = ref({
      name: null,
      measurement_unit: null,
      description: null,
      stock: null,
      stock_alert: null,
      status: 'A',
    })
    const putData = ref({
      name: null,
      measurement_unit: null,
      description: null,
      stock: null,
      stock_alert: null,
      status: 'A',
    })

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const goBack = () => {
      router.replace({
        name: 'views-product-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const saveAction = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          name: data.name,
          measurement_unit: data.measurement_unit,
          description: data.description,
          stock: data.stock,
          stock_alert: data.stock_alert,
          status: data.status,
        })

        resp = await createProduct(postData.value)
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          measurement_unit: data.measurement_unit,
          description: data.description,
          stock: data.stock,
          stock_alert: data.stock_alert,
          status: data.status,
        })
        resp = await editProduct(putData.value, data.id)
      }

      if (!option.value) {
        goBack()
      }

      if (resp.ok) {
        goBack()
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      generalData.value = {
        name: null,
        measurement_unit: null,
        description: null,
        stock: null,
        stock_alert: null,
        status: 'A',
      }
    }

    onMounted(async () => {
      option.value = route.value.params.option
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      dataId.value = route.value.params.id

      if (!option.value) goBack()

      if (option.value && dataId.value) {
        const resp = await getProduct(dataId.value)
        if (resp.ok) {
          generalData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value !== 1) {
        tabs.value.splice(1, 1)
      }
    })

    return {
      tab,
      tabs,
      option,
      dataId,
      generalData,
      postData,
      putData,
      pageData,
      filterData,

      changeTab,
      saveAction,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
